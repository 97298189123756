<!-- =========================================================================================
    File Name: NotAuthorized.vue
    Description: Not Authorized Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
      <div
        id="loading-point"
        class="vs-con-loading__container">
      </div>
      <div v-if="showError">
        <div class="vx-card__title mb-6">
          <h2 class="text-center">{{ $t('Error') }}</h2>
        </div>

        <img src="@/assets/images/pages/reach-admin.png" alt="coming-soon" class="mx-auto mb-2" width="150">


        <div class="">
<!--          <span>Reach admin with <strong><pre>{{ getReference }}</pre></strong></span>-->
          <div class="my-3"></div>
          <vs-button class="w-full" @click="checkLogin()">{{ $t('Retry') }}</vs-button>
          <div class="my-3"></div>
          <vs-button class="w-full" color="danger" @click="logout()">{{ $t('Logout') }}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showError: false,
    }
  },
  computed: {
    getReference() {
      return localStorage.getItem('reference')
    },
  },
  mounted() {
    this.checkLogin()
  },
  methods: {
    logout() {
      if (localStorage.getItem('accessToken')) {
        this.$store.dispatch('auth/logoutUser')
        localStorage.removeItem('accessToken')
        localStorage.clear()
        this.$router.push('/login').catch(() => {})
      }
    },
    setTheme() {
      // TODO: Set Template here
      if (localStorage.getItem('role') == 2) {
        if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 4) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
          this.$store.dispatch('updateTheme', 'light')
          this.$vs.theme({ primary: '#2f1c6d' })
        } else if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 3) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
          this.$store.dispatch('updateTheme', 'light')
          this.$vs.theme({ primary: '#17252a' })
        } else if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 0) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
          this.$store.dispatch('updateTheme', 'light')
          this.$vs.theme({ primary: '#00a1f1' })
        }  else if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 5) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
          this.$store.dispatch('updateTheme', 'light')
          this.$vs.theme({ primary: '#085396' })
        } else if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 1) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'vertical')
          this.$store.dispatch('updateTheme', 'dark')
          this.$vs.theme({ primary: '#00a1f1' })
        } else if (localStorage.getItem('template') == 'null' || localStorage.getItem('template') == 2) {
          this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
          this.$store.dispatch('updateTheme', 'light')
          this.$vs.theme({ primary: '#7cbb00' })
        }
      }
    },
    checkLogin() {
      this.$vs.loading({
        type: 'point',
      })
      this.$store.dispatch('auth/fetchUserDetails')
        .then(() => {
          this.$vs.loading.close()
          this.setTheme()
          if (localStorage.getItem('role')) {
            this.$router.push({ path: '/dashboard' })
          } else{
            window.location.reload()
          }
        })
        .catch((error) => {
          this.showError = true
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
      // if (localStorage.getItem('role')) {
      //   this.$vs.loading.close()
      //   this.$router.push({ path: '/' })
      // } else {
      //
      // }
    },
  },
}

</script>
